@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,400,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext,cyrillic,cyrillic-ext,greek-ext,greek,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300italic,400,400italic,700,700italic&subset=latin,latin-ext,cyrillic-ext,cyrillic,greek-ext,greek,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700&subset=latin,latin-ext,greek-ext,greek,vietnamese,cyrillic,cyrillic-ext");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import "./foundation-sites/scss/foundation";
@import "animation";
@import "./layouts/footer";
@import "./layouts/carousel";
@import "./layouts/navigation";
@import "./layouts/home";
@import "./layouts/about";
@import "./layouts/minigame";

/* Components */
@import "./components/social-media";

@include foundation-everything;

html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* needed for container min-height */
  background: #171717;
  color: #fff;
  display: grid;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
}

#st-game-carousel-logo {
  margin: auto;
  height: auto;
  max-width: 700px;
  max-height: 300px;
  @media (max-width: 420px) {
    top: 80px;
    max-width: 300px;
  }
}

#st-page-header {
  min-height: 300px;
  justify-content: center;

  width: 100%;
  z-index: 1;
  position: relative;
  overflow: hidden;
  font-family: "object-fit: cover;";
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  #st-game-logo {
    margin: auto;
    position: absolute;
    top: 50px;
    z-index: 999;
    height: auto;
    max-width: 700px;
    max-height: 267px;
    @media (max-width: 420px) {
      top: 80px;
      max-width: 300px;
    }
  }

  @media (max-width: 720px) {
    min-height: 350px;
  }
}

#st-page-header.games {
  min-height: 470px;
}

#st-header {
  width: 100%;
  z-index: 1;
  position: relative;
  overflow: hidden;
  font-family: "object-fit: cover;";
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  height: 70vh;
}

#st-header > video,
#st-header > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all ease-in-out 0.2s;
}

#st-page-header > video,
#st-page-header > .st-header-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all ease-in-out 0.2s;
  filter: brightness(80%);
}

.st-section {
  max-width: 1200px;
  margin: auto;
  @media (max-width: 1024px) {
    padding: 10px 5px;
  }
}

h1 {
  font-size: 4rem;
  @media (max-width: 1024px) {
    font-size: 3.5rem;
  }
}

h2 {
  font-size: 2.125rem;
  @media (max-width: 900px) {
    font-size: 1.2rem;
  }
}

h4 {
  @media (max-width: 900px) {
    font-size: 1.1rem;
  }
}

p {
  font-size: 0.9375rem;
  @media (max-width: 900px) {
    font-size: 0.85rem;
  }
}

a {
  color: rgb(211, 211, 211);
  transition: 0.2s all ease-in-out;
}

a:hover {
  color: #fff;
}

a:focus {
  color: #fff;
}

#st-logo {
  padding: 3px;
}

.st-inline-block {
  display: inline-block;
}

.content-align-right {
  text-align: right;
}

.content-align-center {
  text-align: center;
}

.content-align-left {
  text-align: left;
}

/* Buttons */

button {
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 10px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  background: transparent;
  border: rgba(204, 204, 204) solid 2px;
  border-radius: 5px !important;
  color: rgba(204, 204, 204);
}

.st-button-color-DC {
  background: rgba(221, 54, 77, 1);
  color: #fff;
}

.st-button-color-BK {
  background: #ece654;
  color: #000;
}

.st-button-color-BK:hover:enabled {
  background: #fff708;
}

.st-button-color-BK:disabled {
  cursor: not-allowed;
  background: #726d29;
  color: rgba(150, 150, 150, 1);
}

/* Input text */

.st-input-text,
.st-input-text:focus {
  background: #000;
  border-style: solid;
  border-width: 2px;
  color: #fff;
  font-weight: bold;
  padding: 20px;
  font-family: "Montserrat";
}

.st-border-color-DC {
  border-color: rgba(221, 54, 77, 1);
}

.st-border-color-BK {
  border-color: #ece654;
}

.st-input-text ~ label {
  text-align: left;
  color: #fff;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

::placeholder {
  opacity: 0.5; /* Firefox */
}

.fade-in {
  animation: 2s ease fade-in;
}

.fade-in-video {
  animation: 0.5s ease fade-in;
}

label {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 10px;
  transition: all 150ms ease-in;
  color: #676767;
}

label.field-active {
  transform: translateY(-35px);
  left: 20px;
  font-size: 0.9em;
  color: #fff;
  font-weight: bold;
}
.floating-label {
  -webkit-appearance: none !important;
}

#st-loading-logo {
  -webkit-animation: 1s ease fade-in; /* Safari 4.0 - 8.0 */
  animation: 1s ease fade-in;
}

.st-loading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.st-loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.st-loading span:nth-child(3) {
  animation-delay: 0.4s;
}
.disabled {
  pointer-events: none;
}

.link-button {
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 25px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  background: transparent;
  border: rgba(204, 204, 204, 0.5) solid 2px;
  border-radius: 5px !important;
  color: rgba(204, 204, 204);
  position: relative;
  text-align: center;
  max-width: 150px;
  height: 75px;
}

.link-button:hover {
  border: #fff solid 2px;
}

.center {
  margin: auto;
}

.st-bridge-knight-card {
  animation: card-rotate 0.2s ease-in, pulsing-box-shadow 2.5s infinite;
}

.st-bridge-knight-card:hover {
  animation: card-rotate 0.2s ease-in, pulsing-box-shadow-hover 2.5s infinite !important;
}

.st-flipped {
  transform: rotateY(180deg);
}

.finger-paint-regular {
  font-family: "Finger Paint", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#st-game-call-to-action {
  position: relative;
  top: 140px;
  padding: 20px;
  border-color: rgb(192, 192, 192);
  color: #fff;
  backdrop-filter: blur(5px);
}

#st-game-call-to-action:hover {
  border-color: #fff;
}

.st-mailchimp-game {
  position: relative;
  top: 160px;
  @media (max-width: 720px) {
    padding: 0 20px;
  }
}

.hide-for-smallest-devices {
  @media (max-width: 410px) {
    display: none;
  }
}

.st-DC-face-adjust-to-smallest {
  width: 200px;
  @media (max-width: 410px) {
    width: 200px !important;
  }
}

.st-contact-container {
  @media (min-width: 1024px) {
    min-height: 45vh;
  }
}

.st-DC-newsletter {
  top: 50px;
  position: relative;
  @media (max-width: 1024px) {
    top: 0;
  }
}

.container {
  display: flex;
  align-items: center;
}

.st-section-container {
  padding: 40px;
  @media (max-width: 900px) {
    padding: 20px;
  }
}
