.st-profile-img {
  justify-content: center;
  position: relative;
}

.st-dev-img-container {
  border-radius: 50%;
  overflow: hidden;
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  margin-top: 20px;
  display: block;
}

.st-dev-img-container > .st-profile-img {
  width: 90%;
  display: inline;
}

.st-profile-hasan {
  transform: translate(10px, 0);
}

.st-profile-ahmed {
  transform: translate(-5px, 15px);
}

.st-profile-tony {
  transform: translate(-10px, 0);
}

.st-profile-description-box {
  height: 270px;
  padding: 5px;
  @media (max-width: 900px) {
    height: auto;
    padding: 0px;
  }
}

.st-profile-divider-mobile {
  @media (max-width: 1024px) {
    border: 0.5px groove rgba(100, 100, 100, 0.9);
    margin: 0 30px;
  }
}

.st-profile-divider {
  border: 0.5px groove rgba(80, 80, 80, 0.9);
  width: 90%;
  transform: translateX(5%);
  margin: 15px 0;
}

.background-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("https://res.cloudinary.com/dnhvfarsi/image/upload/v1743774861/Website/Img/team-photo_suis1f.webp"); /* or image path */
  background-size: cover;
  background-position: center;
  filter: blur(10px); /* You can increase blur here */
  z-index: 1;
}

.about-box {
  position: relative;
  height: 650px;
  @media (max-width: 1024px) {
    height: 400px;
  }
}
