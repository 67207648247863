.st-navigation-menu {
  padding-right: 30px;
  font-size: 20px;
  font-weight: 200;
  font-family: "Lato", sans-serif;
  @media (max-width: 1024px) {
    padding: 20px;
  }
}

#st-navbar-container {
  max-width: 1200px;
  margin: auto;
}

#st-navbar {
  width: 100%;
  background-color: #1b1c1e;
  left: auto;
  z-index: 1;
  position: relative;
  position: fixed;
  z-index: 100;
  height: 80px;
  @media (max-width: 1024px) {
    height: 60px;
  }
}

.st-adjust-to-nav {
  padding-top: 80px;
  @media (max-width: 1024px) {
    padding-top: 50px;
  }
}

.st-logo-nav {
  width: 60px;
  height: 60px;
  transform: translateY(-2px);
  margin-right: 5px;
}

#st-navbar-menu {
  @media (min-width: 1024px) {
    justify-content: right;
    padding: 25px;
  }
}

.st-logo-nav-name {
  width: 150px;
  margin-left: -15px;
}

@media (min-width: 1024px) {
  .flex-right-desktop {
    display: flex;
    justify-content: flex-end;
  }
  .nav-item-with-submenu {
    position: relative;
    display: inline-block;
  }

  .nav-item-with-submenu:hover .submenu {
    transform: scaleY(1);
  }

  .submenu {
    transform: scaleY(0);
    transform-origin: 0 0%;
    position: absolute;
    background-color: #1b1c1e; /* or match your theme */
    padding: 10px;
    min-width: 135px;
    margin-top: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
    transition: transform 0.1s ease-in-out;
  }

  .nav-item-with-submenu:hover .submenu {
    display: block;
  }

  .nav-item-with-submenu a {
    width: 100%;
    height: 100%;
    display: block;
  }

  .st-submenu-item {
    font-size: 1rem;
    color: white;
  }

  .submenu:hover {
    background-color: gray;
  }
}

@media (max-width: 1024px) {
  .responsive-nav-button {
    width: 100%;
    display: block;
    padding: 10px;
  }

  .submenu {
    position: relative;
    transform: none;
    margin-top: 0;
    margin-bottom: 0;
  }

  .st-submenu-item {
    font-size: 1.2rem;
    color: white;
  }

  .nav-item-with-submenu.active .submenu {
    display: block;
  }

  .nav-item-with-submenu {
    position: relative;
  }

  .st-logo-nav-name {
    width: 100px;
  }

  #st-navbar-menu {
    z-index: 99;
    transition: all 0.4s ease-in-out;
    transform: translateX(150%);
    background-color: #000;
    height: 200vh;
    justify-content: left;
  }

  #navItem {
    padding: 10px;
    font-size: 24px;
  }
  #st-navbar-menu.open {
    transform: translateX(65%);
  }

  #st-navbar-main.open {
    background-color: rgba(0, 0, 0, 0.8);
  }

  #st-navbar-menu {
    display: flex !important;
    flex-flow: column nowrap !important;
  }

  #st-navbar-menu > a {
    margin-bottom: 30px;
  }

  .st-menu-icon {
    position: absolute !important;
    top: 15px;
    right: 15px;
    z-index: 99999;
    width: 10px;
    min-width: 0;
    border: 0;
    padding: 12px;
    pointer-events: visible;
  }

  .st-logo-nav {
    width: 50px;
    height: 50px;
    padding-right: 10px;
  }

  #st-navbar-container {
    pointer-events: none;
  }

  #st-navbar-container.open {
    pointer-events: visible;
  }

  .menu-icon {
    width: 30px !important;
  }

  .menu-icon::after {
    height: 4px !important;
    box-shadow: 0 11px 0 #fefefe, 0 23px 0 #fefefe !important;
  }

  /* Sliding effect for navbar */
  .navbar-scroll {
    position: fixed;
    top: 0;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    z-index: 999;
  }

  .navbar-scroll.hidden {
    transform: translateY(-100%);
  }

  .navbar-scroll.visible {
    transform: translateY(0);
  }
}
